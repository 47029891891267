import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["currentPeriod", "transactions"]
    connect() {
        this.load("/transactions");
    }

    previous() {
        this.load(this.previousPeriodUrl);
    }

    next() {
        this.load(this.nextPeriodUrl);
    }

    load(url) {
        this.transactionsTarget.innerHTML = "<div class=\"loader transactions-loader\"></div>";
        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: (data) => {
                this.currentPeriodTarget.innerHTML = data.currentPeriod;
                this.transactionsTarget.innerHTML = data.transactions;
                this.previousPeriodUrl = data.previousPeriodUrl;
                this.nextPeriodUrl = data.nextPeriodUrl;
            }
        })
    }
}